const storeModule = require('../../store/seller-estimate');
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'InspectorSellerEstimate',
	init({ store, context }) {
		store.registerModule('sellerEstimate', storeModule);

		let cookies = {};
		if (context) {
			cookies = context.request.headers.cookie;
		}
		return store
			.dispatch('sellerEstimate/list', { cookies })
			.then(({ ids }) => store
				.dispatch('sellerEstimate/information', ids)
				.catch(_ => {
					console.error('ERROR [GRAPH_QL API] sellerEstimate')
				})
			);
	},
	data() {
		return {
			page: 1
		}
	},
	components: {
		Item: require('./Item/index.vue').default,
		Pagination: require('../Common/Pagination/index.vue').default,
		LastSearch: require('../AdvancedSearch/SearchHistory/index.vue').default,
	},
	computed: {
		...mapGetters({
			ids: 'sellerEstimate/ids',
			userData: 'Common/userData',
			user: 'sellerEstimate/user',
			count: 'sellerEstimate/count',
			info: 'sellerEstimate/info',
			states: 'sellerEstimate/states',
		}),
		pageCount() {
			return Math.ceil(this.count / 10);
		},
	},
	methods: {
		...mapActions('sellerEstimate', {
			getReasons: 'reasons',
			list: 'list',
			information: 'information',
		}),
		showSplitter(index) {
			const { ids = [], states } = this;
			const prev = ids[index - 1];
			const curr = ids[index];
			if(prev && curr) {
				const currState = states(curr.id);
				const prevState = states(prev.id);
				return (!prev.hasHistory && prevState?.contact !== false && !prevState?.done)
					&& (curr.hasHistory || currState?.contact === false || currState?.done) && !currState.showInspector
			} else if (this.page === 1 && !prev && curr) {
				const currState = states(curr.id);
				return (curr?.hasHistory || currState?.contact === false || currState?.done) && !currState.showInspector;
			}
			return false;
		},
		onChangePage(page) {
			this.page = page;
			this
				.list({ page })
				.then(({ ids }) => this.information(ids))
		}
	},
	async mounted() {
		this.getReasons();
		await this.list()

		const notLoaded = this.ids.filter(({ id }) => this.info[id]);
		if (notLoaded.length) {
			this.information(notLoaded);
		}
	},
	metaInfo() {
		return {
			title: 'AUTO.RIA™ — Оцінка продавця',
			meta: [
				{ name: 'description', content: '' },
				{ name: 'keywords', content: '' },
				{ property: 'og:title', content: '' },
				{ property: 'og:description', content: '' },
				{ name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW' }
			],
			link: [
				{ rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/' },
				{ rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/' },
				{ rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/' },
				{ rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/' },
				{ rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/' }
			]
		};
	},
	i18n: {
		messages: {
			uk: {
				'Оцените продавцов и оставьте отзыв': 'Оцініть продавців та залиште відгук',
				'ваши оценки и отзывы формируют доверие к продавцу и помогают покупателям купить авто честно и безопасно': 'ваші оцінки та відгуки формують довіру до продавця та допомагають покупцям купити авто чесно та безпечно',
				'Продавцы, которых вы оценили раньше': 'Продавці, яких ви оцінили раніше',

			},
			ru: {
				'Оцените продавцов и оставьте отзыв': 'Оцените продавцов и оставьте отзыв',
				'ваши оценки и отзывы формируют доверие к продавцу и помогают покупателям купить авто честно и безопасно': 'ваши оценки и отзывы формируют доверие к продавцу и помогают покупателям купить авто честно и безопасно',
				'Продавцы, которых вы оценили раньше': 'Продавцы, которых вы оценили раньше',
			}
		}
	}
};
