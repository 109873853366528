import _laxios from '../_laxios';
import Vue from 'vue';
const sellerEstimateQuery = require('../_graphql/queries/sellerEstimate');

module.exports = {
	namespaced: true,
	state: Object.assign({
		count: 0,
		ids: [],
		info: {},
		states: {},
		user: {},
		reasons: { negative: [], positive: [] },
	}, __INITIAL_STATE__['sellerEstimate']),
	actions: {
		list({ commit, getters }, { cookies, page = 1 } = {}) {
			let headers;
			if (cookies) {
				headers = { Cookie: cookies };
			}

			commit('ids', []);
			return _laxios
				.getInspectorEstimateList
				.request({ responseType: 'json', headers, params: { page }})
				.then((payload) => {
					commit('ids', payload.ids);
					commit('count', payload.count);
					commit('states', payload.states);
					commit('user', payload.user);
					return payload;
				})
				.catch((e) => {
					console.error('getInspectorEstimateList', e.message);
				});
		},
		information({ commit, rootGetters }, ids) {
			let {'lang/id': langId } = rootGetters;

			return _laxios
				.graphql
				.request({ data: { query: sellerEstimateQuery, variables: { langId, ids: ids.map(({ id }) => id) } } })
				.then(({ data: { get } = {} } = {}) => get)
				.then((payload) => {
					commit('info', payload);
					return payload;
				}).catch((e) => {
					console.error('information graphql', e.message);
				});
		},
		save({ commit, getters }, { id }) {
			return _laxios
				.saveInspectorEstimateState
				.expandUrl({ id })
				.request({ responseType: 'json' }).catch((e) => {
					console.error('saveInspectorEstimateState', e.message);
				});
		},
		saveUserRating({ commit }, { id, value }) {
			return _laxios
				.saveUserRating
				.expandUrl({ id })
				.request({ responseType: 'json', data: { value }})
				.catch(error => {
					if (error.response.status === 400) {
						return _laxios
							.getUserRating
							.expandUrl({ id })
							.request({ responseType: 'json' })
							.then(([ rating = {} ] = []) => rating);
					}
					throw error;
				});
		},
		reasons({ commit, rootGetters }, type = 'all') {
			let {'lang/id': langId } = rootGetters;
			return _laxios
				.getInspectorReasons
				.expandUrl({type})
				.request({ responseType: 'json', params: { langId } })
				.then((payload) => {
					commit('reasons', payload);
					return payload
				}).catch((e) => {
					console.error('getInspectorReasons', e.message);
				});
		},
		change({ commit }, { id, ...data }) {
			return _laxios
				.changeInspectorEstimateState
				.expandUrl({ id })
				.request({ responseType: 'json', data }).catch((e) => {
					console.error('changeInspectorEstimateState', e.message);
				});
		}
	},
	mutations: {
		ids(state, ids) {
			Vue.set(state, 'ids', ids);
		},
		user(state, info) {
			Vue.set(state, 'user', info);
		},
		count(state, value) {
			Vue.set(state, 'count', value);
		},
		info(state, payload) {
			let _payload = payload;
			if (Array.isArray(payload)) {
				_payload = payload
					.reduce((memo, item) => {
						memo[item.id] = item;
						return memo;
					}, {});
			}
			Vue.set(state, 'info', _payload);
		},
		reasons(state, { positive = [], negative = [] }) {
			Vue.set(state, 'reasons', { positive, negative });
		},
		states(state, payload) {
			Vue.set(state, 'states', payload);
		},
		setState(state, {id, payload}) {
			Vue.set(state.states, id, payload);
		}
	},
	getters: {
		ids({ ids }) {
			return ids;
		},
		count({ count }) {
			return count;
		},
		user({ user }) {
			return user;
		},
		info({ info }) {
			return info;
		},
		states({ states }) {
			return (id) => states[id] || {};
		},
		reasons({ reasons }) {
			return reasons;
		}
	}
};
