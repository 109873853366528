import { mapGetters, mapActions, mapMutations } from 'vuex';
const bpm = require('@bu-auto-modules/bpm');
const { get } = require('../../../../helpers/cookie');

export default {
	name: 'Estimate',
	props: {
		id: Number,
	},
	data() {
		return {
			tryToSubmit: false,
		};
	},
	mixins: [require('../../../../mixins/slonEventLogger')],
	computed: {
		...mapGetters({
			_reasons: 'sellerEstimate/reasons',
			_info: 'sellerEstimate/info',
			states: 'sellerEstimate/states',
			user: 'sellerEstimate/user',
		}),
		info() {
			return this._info[this.id];
		},
		state: {
			get() {
				return this.states(this.id);
			},
			set(payload) {
				return this.setState({ id: this.id, payload });
			}
		},
		showContact() {
			const { contact, done } = this.state;
			return typeof contact !== 'boolean' && !done;
		},
		showRating() {
			const { contact, rating, done } = this.state;
			return !this.showContact && !rating && contact && !done;
		},
		showSubmitBlock() {
			const { contact, done } = this.state;
			return !this.showRating && contact && !this.showContact && !done;
		},
		showThxBlock() {
			return (!this.showRating && !this.showContact && !this.showSubmitBlock) || this.state.done;
		},
		reasons() {
			const { rating } = this.state;
			const section = rating > 3 ? 'positive' : 'negative';
			return this._reasons[section] || [];
		},
		currentReason() {
			return this.reasons.filter(({id}) => this.state.reason === id)[0] || {};
		},
		photo() {
			const { main } = this.info.photos || {};
			if (main?.id) {
				return `https://cdn0.riastatic.com/photosnew/auto/photo/_____${main?.id}bx`;
			}
			return '';
		},
	},
	methods: {
		...mapActions({
			_change: 'sellerEstimate/change',
			save: 'sellerEstimate/save',
			saveUserRating: 'sellerEstimate/saveUserRating',
		}),
		...mapMutations({
			setState: 'sellerEstimate/setState',
		}),
		change(payload) {
			return this
				._change({ id: this.id, ...Object.assign({}, this.state, payload) })
				.then(data => this.state = data);
		},
		isContact(e) {
			this.change({ contact: Boolean(Number(e.target.value)) });
		},
		changeRating({ target = {} } = {}) {
			if (Number(target.value) >= 4) {
				this.sendEventToSlonik(117, {action_block: 2, proposal_id: this.id});
			}
			this.change({ rating: Number(target.value) });
		},
		setReason({ target = {} } = {}) {
			this.change({ reason: Number(target.value) });
		},
		submit() {
			this.tryToSubmit = true;
			const { info, state, currentReason, id } = this;
			if (!currentReason.id || !state.text) {
				return;
			}

			const user = {
				email: this.user?.email || '',
				fio: this.user?.name || '',
				phones: this.user?.phones.join(','),
				user_id: this.user?.id || '',
				ui: get('ui')
			};

			const appealInfo = {
				titleFeedback: `Оценка продавца(web) ${this.state.rating}`,
				textFeedback: this.state.text,
				advLink: `https://auto.ria.com/uk/auto___${info.id}.html`,
				complaintType: `Оценка продавца(web) ${this.state.rating} - ${this.currentReason.title}`,
				typeReview: 1,
				internetInspector: 1,
				link: location.href
			};

			const device = {
				userAgent: navigator.userAgent,
				isMobile: this.isMobile,
				appVersion: navigator.appVersion,
				platform: navigator.platform,
				screenWidth: screen.width,
				screenHeight: screen.height
			};

			const advertisement = {
				markaName: info.model.name,
				modelName: info.brand.name,
				year: info.year,
				sellerPrice: info?.price?.all?.USD?.value,
				userId: info?.owner?.id,
				rating: state.rating,
				sellerHash: currentReason.hash,
				link: location.href,
				id,
				phones: info.owner.phones.map(({ formatted }) => formatted).join(','),
				ownerName: info.owner.name,
				photoLink: this.photo,
			};

			const rating = Number(this.state.rating);
			const additionalParam = {
				reasonId: currentReason.id,
				reasonName: currentReason.title,
				sendToCM: false,
				sendToBPM: Boolean(((this.state.text && rating !== 10) || rating < 7) && (user.email || user.phones)),
			};

			return this
				.saveUserRating({id: this.info.owner.id, value: state.rating})
				.then(({ ratingId }) => {
					if (this.state.rating <= 3) {
						this.sendEventToSlonik(119, {action_block: 2, proposal_id: this.id, auto_complain_1: this.currentReason?.id, ticket_id: ratingId});
					}
					return bpm.sendImpressions(
						user, appealInfo, device, Object.assign(advertisement, { ratingId }), '/users/sendImpressions/', additionalParam);
				}
				)
				.then(isSend => isSend && this.save({id: this.id}).then((state) => this.state = state));
		}
	},
	i18n: {
		messages: {
			uk: {
				'Вам удалось пообщаться с этим продавцом': 'Вам вдалося поспілкуватися з цим продавцем',
				'Нет': 'Ні',
				'Да': 'Так',
				'Напишите свой отзыв': 'Напишіть свій відгук',
				'Проверить авто': 'Перевірити авто',
				'Спасибо за отзыв': 'Дякуємо за відгук',
				thxMessage: 'Якщо вас зацікавило це авто, радимо перевірити його розширеними перевірками по VIN-коду та в мережі сертифікованих AUTO.RIA центрів технічних перевірок',
				'Спасибо, что написали': 'Дякуємо, що написали',
				tmpMessage: 'Кібер-інспектор RIA ретельно вивчить ваш відгук. Ми сповістимо про результат перевірки проиягом кількох днів',
				tmpMessage2: 'Слідкуйте за результатом перевірки та новими досягненнями',
				'Отправить отзыв': 'Надіслати відгук',
				'Проверить достижения': 'Перевірити досягнення',
				'Оцените продавца и напишите отзыв': 'Оцініть продавця та напишіть відгук',
			},
			ru: {
				'Вам удалось пообщаться с этим продавцом': 'Вам удалось пообщаться с этим продавцом',
				'Нет': 'Нет',
				'Да': 'Да',
				'Напишите свой отзыв': 'Напишите свой отзыв',
				'Проверить авто': 'Проверить авто',
				thxMessage: 'Если вас заинтересовало это авто, советуем проверить его расширенными проверками по VIN-кода и в сети сертифицированных AUTO.RIA центров технических проверок',
				'Спасибо за отзыв': 'Спасибо за отзыв',
				tmpMessage: 'Кибер-инспектор RIA тщательно изучит ваш отзыв. Мы сообщим о результате проверки проиягом нескольких дней',
				tmpMessage2: 'Следите за результатом проверки и новыми достижениями',
				'Спасибо, что написали': 'Спасибо, что написали',
				'Отправить отзыв': 'Отправить отзыв',
				'Проверить достижения': 'Проверить достижения',
				'Оцените продавца и напишите отзыв': 'Оцените продавца и напишите отзыв',

			}
		}
	}
};
