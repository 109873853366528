import { mapGetters } from 'vuex';

export default {
	name: 'Item',
	mixins: [require('../../../mixins/humanize')],
	props: {
		id: Number
	},
	components: {
		Estimate: require('./Estimate/index.vue').default,
	},
	computed: {
		...mapGetters({
			_info: 'sellerEstimate/info',
		}),
		info() {
			return this._info[this?.id] || {};
		},
		owner() {
			const { info: { owner = {} } = {} } = this;
			return {
				...owner,
				photo: owner?.avatar ?
					`https://cdn.riastatic.com/photos/${owner?.avatar.replace('.jpg', 'm.jpg')}` :
					'https://css.riastatic.com/images/demo/avatar-24x24.svg'
			};
		},
		rating() {
			return this.owner?.rating || {};
		},
		photo() {
			const { main } = this.info?.photos || {};
			if (main?.id) {
				return `https://cdn0.riastatic.com/photosnew/auto/photo/_____${main?.id}bx`;
			}
			return '';
		},
		price() {
			const { all: { USD, UAH } = {} } = this.info.price || {};
			return { USD: this.humanizeNumber(USD?.value), UAH: this.humanizeNumber(UAH?.value) };
		},
		city() {
			const { city: { name } = {} } = this.info?.location || {};
			return name;
		}
	},
	methods: {},
	i18n: {
		messages: {
			ru: {
				'Продавец': 'Продавец',
				'оценка продавца от': 'оценка продавца от',
				'покупатель': 'покупатель | покупателя | покупателей | покупателей'
			},
			uk: {
				'Продавец': 'Продавець',
				'оценка продавца от': 'оцінка продавця від',
				'покупатель': 'покупець | покупця | покупців | покупців',
			}
		}
	}
};
